import React from "react"
import imgHero from "../../images/avaters/Group-135.svg"
import { useAuth0 } from "../../services/auth"
import { Link } from "gatsby"
const Hero = () => {
  const { isAuthenticated, loginWithRedirect, gaClientId } = useAuth0()
  return (
    <section className="home">
      <div className="max-w-7xl mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
        <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
          <h1 className="text-3xl md:text-4xl lg:text-6xl mb-4 font-semibold text-slate-700">
            Click'n'Pay
          </h1>
          <h4 className="text-lg md:text-xl lg:text-2xl mb-4 font-medium  text-slate-600">
          acquisti servizi, ottieni profitti!
          </h4>
          <p className="mb-8 text-base md:text-lg leading-relaxed text-slate-500">
          <span className="font-normal">
                  Click'n'Pay è una piattaforma utile e vantaggiosa
                </span>
                <br /> Per ogni servizio che utilizzi, finalizzando l'acquisto,
                ottieni dei punti. Punti che potranno poi essere convertiti
                in buoni spesa di diverse categorie merceologiche
          </p>
          <div className="">
            {!isAuthenticated ? (
              <div className="flex flex-col gap-3 lg:flex-row">
                <button
                  onClick={() => loginWithRedirect({gaClientId: gaClientId})}
                  className="super-btn py-3"
                >
                  Registrazione gratuita
                </button>
                <button
                onClick={() => loginWithRedirect({gaClientId: gaClientId})}
                className="super-btn super-secondary-btn py-3"
              >
                  Accedi
                </button>
              </div>
            ) :
            (
              <div className="flex flex-col gap-3 lg:flex-row">
              <Link
                to="#services"
                className="super-btn py-3"
              >
                Vai ai servizi
              </Link>
              <Link
              to="/account/profile"
              className="super-btn super-secondary-btn py-3"
            >
                Area riservata
              </Link>
            </div>
            )}
          </div>
        </div>
        <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
          <img
            className="object-cover object-center"
            alt="hero"
            src={imgHero}
          />
        </div>
      </div>
    </section>
  )
}

export default Hero
