import React, { useEffect } from "react"
import Mobile from "../../images/Mobile-1.svg"
import Guarantee from "../../images/Guarantee-2.svg"
import Cash from "../../images/Cash-1.svg"
import Analytic from "../../images/Analytic-1.svg"
import Aos from "aos"

const vantaggi = [
  {
    name: "Comoda",
    description:
      "perché potrai consultare i prodotti presenti ed effettuare i tuoi acquisti dimenticandoti di traffico, file e soprattutto orari di apertura o chiusura attività",
    imgUrl: Mobile,
  },
  {
    name: "semplice",
    description:
      "perché in pochi passaggi potrai selezionare la macroarea di interesse, quindi il servizio o il prodotto che vuoi acquistare, seguire le semplici indicazioni e…voilà, basterà procedere al pagamento e l’operazione è conclusa!",
    imgUrl: Cash,
  },
  {
    name: "sicura e garantita",
    description:
      "perché la nostra esperienza pluriennale nella consulenza finanziaria e assicurativa e nel B2B in generale, ci ha permesso di conoscere e dunque di selezionare per te solo le realtà aziendali più serie, qualificate e performanti",
    imgUrl: Guarantee,
  },
  {
    name: "affidabile",
    description:
      "perché dietro alla piattaforma ci sono persone che lavorano quotidianamente per garantire che tutto il processo funzioni al meglio e per supportarti in caso di dubbi e necessità",
    imgUrl: Analytic,
  },
]
const Vantaggi = () => {
  useEffect(() => {
    Aos.init({
      duration: 1000,
      easing: "ease-in-out",
      anchorPlacement: "top-bottom",
    })
  }, [])
  return (
    <section className="py-12 bg-amber-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="lg:text-center">
          <p className="mt-2 text-3xl leading-8 font-bold text-gradient sm:text-4xl">
            Perché Click'n'Pay
          </p>
          <p className="mt-4 max-w-2xl text-xl text-sky-900 lg:mx-auto">
            <span className="text-lg leading-6 font-semibold text-sky-900">
              Click'n'Pay
            </span>{" "}
            è una piattaforma multiservizi e multibrand che ti permette di
            usufruire di una vasta gamma di prodotti e utilities direttamente da
            pc o smartphone senza muoverti da casa.
          </p>
        </div>

        <div className="mt-10">
          <div className="space-y-10 md:space-y-0 md:grid lg:grid-cols-4 md:grid-cols-2 md:gap-x-8 md:gap-y-10">
            <>
              {vantaggi.map(vantaggio => (
                <div
                  key={vantaggio.name}
                  data-aos="fade-up"
                  className="flex flex-col"
                >
                  <div className="flex rounded-md mb-6">
                    <img
                      src={vantaggio.imgUrl}
                      alt={vantaggio.name}
                      className="h-16 w-16 md:h-20 md:w-20 lg:h-24 lg:w-24"
                    />
                  </div>
                  <p className="text-lg leading-6 font-semibold text-sky-900 uppercase">
                    {vantaggio.name}
                  </p>
                  <p className="mt-2 text-base text-sky-700">
                    {vantaggio.description}
                  </p>
                </div>
              ))}
            </>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Vantaggi
