import React, { useEffect } from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Aos from "aos"
const News = () => {
  const data = useStaticQuery(query)
  const ultimeNews = data.allContentfulBlog.nodes
  useEffect(() => {
    Aos.init({
      duration: 1000,
      easing: "ease-in-out",
      anchorPlacement: "top-bottom",
    })
  }, [])
  return (
    <div className="py-12 bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="lg:text-center">
          <p className="mt-2 text-3xl leading-8 font-bold tracking-tight text-gradient uppercase text-center sm:text-4xl">
            Ultime news
          </p>
        </div>

        <div className="mt-10">
          <div
            data-aos="fade-up"
            className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10"
          >
            {ultimeNews.map(news => {
              const { id, title, description, date, image, slug } = news
              const pathToImage = getImage(image)
              return (
                <div
                  key={id}
                  className="max-w-md mx-auto bg-amber-50 rounded-xl shadow-lg overflow-hidden lg:max-w-2xl"
                >
                  <div className="lg:flex">
                    <div className="lg:shrink-0">
                      <GatsbyImage
                        image={pathToImage}
                        alt={title}
                        className="h-48 w-full object-cover lg:h-full lg:w-48"
                      />
                    </div>
                    <div className="p-8">
                      <Link to={`/news/${slug}`}>
                        <div className="uppercase tracking-wide text-sm text-super font-semibold">
                          {date}
                        </div>
                        <p className="block mt-1 text-lg leading-tight font-medium text-gray-900">
                          {title}
                        </p>
                        <p className="mt-2 text-gray-500 line-clamp-3">
                          {description}
                        </p>
                      </Link>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default News
const query = graphql`
  {
    allContentfulBlog(sort: {fields: createdAt, order: DESC}, limit: 4) {
      nodes {
        id
        image {
          gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
        }
        title
        description
        date(formatString: "DD-MM-YYYY")
        slug
      }
    }
  }
`
