import React, { useEffect } from "react"
import Aos from "aos"
import Lottie from "lottie-react"
import zoom from "../../images/json/click.json"
import rules from "../../images/json/rules-1.json"
import savings from "../../images/json/savings-1.json"
const vantaggi = [
  {
    name: "Ti iscrivi",
    description:
      "Iniziare ad utilizzare CnP è semplicissimo, basta che ti registri con una mail e una password o con il tuo account Google",
    imgUrl: zoom,
  },
  {
    name: "Scegli il servizio",
    description:
      "Una volta registrato, inizi a navigare scegliendo il servizio di tuo interesse, sottoscrivi l'offerta e concludi l'acquisto",
    imgUrl: rules,
  },
  {
    name: "Ottieni punti",
    description:
      "Per ogni operazione finalizzata accumuli punti. Raggiunti dei goal avrai diritto a dei buoni spesa di vario tipo e importo",
    imgUrl: savings,
  },
]
const ComeFunziona = () => {
  useEffect(() => {
    Aos.init({
      duration: 1000,
      easing: "ease-in-out",
      anchorPlacement: "top-bottom",
    })
  }, [])
  return (
    <section className="py-14 bg-super bg-[url('../images/pattern.svg')] bg-no-repeat bg-cover bg-center">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="lg:text-center">
          <p className="mt-2 text-3xl leading-8 font-bold text-gradient sm:text-4xl text-center uppercase">
            Come funziona
          </p>

          <div className="mt-10">
            <div className="space-y-10 md:space-y-0 md:grid lg:grid-cols-3 md:grid-cols-3 md:gap-x-6 md:gap-y-10">
              <>
                {vantaggi.map(vantaggio => (
                  <div
                    data-aos="fade-up"
                    key={vantaggio.name}
                    className="flex flex-col items-center"
                  >
                    <div className="w-20 h-20 md:w-28 md:h-28 lg:w-32 lg:h-32 bg-white rounded-full mb-6 flex justify-center items-center">
                      <Lottie
                        animationData={vantaggio.imgUrl}
                        loop
                        /* className="w-14 h-14 md:w-16 md:h-16 lg:w-20 lg:h-20" */
                      />
                    </div>
                    <p className="text-xl leading-6 font-semibold text-sky-700 text-center">
                      {vantaggio.name}
                    </p>
                    <p className="mt-2 text-lg text-sky-900 text-center">
                      {vantaggio.description}
                    </p>
                  </div>
                ))}
              </>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ComeFunziona
