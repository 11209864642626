import React, { useEffect } from "react"
import Aos from "aos"
import Lottie from "lottie-react"
import mask from "../../images/json/msk-02.json"
import { BsFillArrowRightCircleFill } from "react-icons/bs"
import { Link } from "gatsby"
import {
  IoCarSportOutline,
  IoTrendingUpSharp,
  IoBulbOutline,
  IoPhonePortraitOutline,
  IoCardOutline,
  IoShieldCheckmarkOutline,
} from "react-icons/io5"
export const features = [
  {
    name: "Luce e gas",
    description:
      "Guarda le tariffe dei principali gestori di luce e gas presenti sul mercato per contratti business o residenziali e scegli quella più adatta per te",
    href: "/servizi/luce-gas",
    icon: IoBulbOutline,
  },
  {
    name: "Telefonia",
    description:
      "Scopri le tariffe sulla telefonia fissa e mobile dei principali gestori e scegli l’offerta più giusta per te o per la tua società",
    href: "/servizi/telefonia",
    icon: IoPhonePortraitOutline,
  },
  {
    name: "Assicurazioni",
    description:
      "Stipula la tua polizza assicurativa online. Velocità e risparmio con alle spalle la garanzia di gruppi assicurativi consolidati.",
    href: "/servizi/assicurazioni",
    icon: IoShieldCheckmarkOutline,
  },
  {
    name: "Conti correnti e carte",
    description:
      "Scegli il conto corrente online più adatto alle tue esigenze. Per registrarti bastano pochi minuti e la gestione sarà più semplice e conveniente che in filiale.",
    href: "/servizi/conti-correnti",
    icon: IoCardOutline,
  },
  {
    name: "Crypto e trading",
    description:
      "Investi con facilità nei principali mercati finanziari mondiali scegliendo tra alcune delle migliori piattaforme d’investimento tradizionali e di criptovalute.",
    href: "/servizi/crypto-trading",
    icon: IoTrendingUpSharp,
  },
  {
    name: "Biglietti e prenotazioni",
    description:
      "Prenota le tue vacanze con facilità e velocità. Il nostro servizio di biglietti e prenotazioni ti permetterà di riservare le tue vacanze in tutto il mondo.",
    href: "/servizi/biglietti",
    icon: IoCarSportOutline,
  },
  {
    name: "Noleggio auto",
    description:
      "Devi noleggiare un’auto o un furgone? Sei nel posto giusto. Noleggio a breve e lungo termine dei principali marchi con richiesta direttamente online.",
    href: "/servizi/noleggio-auto",
    icon: IoCarSportOutline,
  },
  {
    name: "E-commerce",
    description:
      "Scegli tra una selezione di siti di vendita online, per trovare le offerte migliori sui prodotti e i brand che più ti piacciono, con un semplice click.",
    href: "/servizi/e-commerce",
    icon: IoCarSportOutline,
  },
]

const Risparmiare = () => {
  useEffect(() => {
    Aos.init({ duration: 2000 })
  }, [])
  return (
    <section className="bg-white bg-[url('../images/Vector.svg')] bg-no-repeat bg-cover -mb-3" id="services">
      <div
        data-aos="fade"
        className="max-w-2xl mx-auto py-14 px-4 grid items-center grid-cols-1 gap-x-16 sm:px-6 sm:py-32 lg:max-w-7xl lg:px-8 lg:grid-cols-2"
      >
        <>
          <div className="order-2">
            <dl className="mt-8 grid grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-2 lg:gap-y-4 lg:gap-x-4">
              {features.map(feature => (
                <div
                  key={feature.name}
                  className="border border-amber-100 py-4 px-4 rounded-md bg-amber-50 shadow-lg flex justify-between items-center"
                >
                  <div className="">
                    <dt className="font-medium text-base text-sky-700  mb-2">
                      {" "}
                      {feature.name}
                    </dt>
                    <dd className="mt-2 text-sm text-gray-500">
                      {feature.description}
                    </dd>
                    <dd>
                      <Link
                        to={feature.href}
                        className="px-2 py-1 mt-2 text-sm text-sky-700  inline-flex items-center justify-between rounded-md hover:bg-super hover:border-transparent hover:transition ease-in-out duration-300 focus:outline-none focus:ring-2 focus:ring-super focus:ring-offset-2"
                      >
                        SCOPRI DI PIÙ
                        <BsFillArrowRightCircleFill className="ml-2" />
                      </Link>
                    </dd>
                  </div>
                </div>
              ))}
            </dl>
            <span className="text-xs md:text-sm italic text-red-500">
              *Per saperne di più consulta i Termini e Condizioni
            </span>
          </div>
          <div className="grid order-1">
            <div className="pb-0 md:pb-6">
              <h2 className="text-xl md:text-3xl font-bold text-gradient uppercase mb-3">
                Più servizi, più benefits
              </h2>
            </div>
            <Lottie
              animationData={mask}
              loop
              as="style"
              rel="stylesheet preload prefetch"
              className="shadow-2xl rounded-lg hidden lg:block "
            />
          </div>
        </>
      </div>
    </section>
  )
}

export default Risparmiare
