import React from "react"

import { graphql, useStaticQuery } from "gatsby"
import ProductSlider from "../ProductSlider"

const Operatori = () => {
  const data = useStaticQuery(query)
  const items = data.allMysqlPrograms.nodes
  return (
    <section className="operatori py-14 bg-blue-50">
      <div className="">
        <div className="text-center">
          <p className="my-2 text-3xl leading-8 font-bold text-gradient uppercase  text-center sm:text-4xl">
            Operatori e partnerships
          </p>
        </div>
        <ProductSlider items={items} />
      </div>
    </section>
  )
}

export default Operatori

const query = graphql`
  {
    allMysqlPrograms(
      filter: { products: { elemMatch: { category_id: { in: 5 } } } }
    ) {
      nodes {
        id
        mysqlId
        logo
        name
        enabled

        products {
          category_id
          display_name
          reflink
          json_metadata
        }
      }
    }
  }
`
