import React from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, {
  Navigation,
  Pagination,
  EffectFade,
  Parallax,
  Autoplay,
} from "swiper/core"
import img1 from "../../images/prizes/bimbostore.png"
import img2 from "../../images/prizes/carrefour.png"
import img3 from "../../images/prizes/pam.png"
SwiperCore.use([Navigation, Pagination, EffectFade, Parallax, Autoplay])
const CardSlide = () => {
  const data = [
    {
      id: 1,
      img: img1,
      title: "Bimbo Store",
      text: "La Gift Card Toys Center è cumulabile e spendibile in più soluzioni presso tutti i punti vendita Toys Center e Bimbostore che aderiscono all'iniziativa, ad esclusione dei Bimbostore in franchising (FOGGIA - TRICASE - MAGLIE - MASSAFRA - MONTEVARCHI - AREZZO - CREMONA - GIOIA DEL COLLE).",
    },
    {
      id: 2,
      img: img2,
      title: "Carrefour",
      text: "Le Gift Card Carrefour hanno validità 6 mesi. Possono essere utilizzate a scalare per acquistare tutti i prodotti in vendita ad eccezione di farmaci da banco e carburanti. Sono cumulabili con altre Gift Card Carrefour e con altri metodi di pagamento fino ai limiti di legge.",
    },
    {
      id: 3,
      img: img3,
      title: "Pam Panorama",
      text: "La Gift Card Pam Panorama è uno strumento di pagamento che può essere utilizzato per l’acquisto di qualsiasi prodotto ad esclusione di ricariche telefoniche, carte prepagate o qualsiasi altro tipo di Gift Card ed inoltre non può essere utilizzata per il pagamento di bollettini postali.",
    },
  ]
  return (
    <div className="max-w-full mx-auto">
      <Swiper
        slidesPerView={1}
        spaceBetween={1}
        loop={true}
        pagination={{
          clickable: true,
        }}
        centeredSlides={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        }}
        navigation={false}
        speed={1200}
        parallax={true}
        effect={"fade"}
        className="mySwiper flex"
        style={{ height: "30rem" }}
      >
        {data.map(item => {
          const { id, title, img, link, text } = item

          return (
            <SwiperSlide key={id}>
              <div className="w-full h-full">
                <img
                  src={img}
                  alt={title}
                  className="object-cover w-full h-full"
                />
              </div>

              <div className="absolute inset-0 transition-opacity duration-200 bg-black bg-opacity-80  opacity-100 justify-evenly items-center">
                <div className="px-4 sm:px-12 py-12 mx-auto max-w-full">
                  <div className="flex justify-between items-center">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                      <div className="">
                        <h2
                          className="mb-4 title text-lg lg:text-4xl text-center lg:text-left font-bold text-super shadow-2xl"
                          data-swiper-parallax="700"
                        >
                          {title}
                        </h2>
                        <p
                          className="mb-6 text-amber-200 hidden md:block text-base lg:text-xl"
                          data-swiper-parallax="-400"
                        >
                          {text}
                        </p>
                      </div>
                      <div className="">
                          <img
                            src={img}
                            alt={title}
                            className="object-contain  w-full"
                          />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          )
        })}
      </Swiper>
    </div>
  )
}

export default CardSlide
