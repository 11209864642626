import React from "react"
import Layout from "../components/Layout"
import Hero from "../components/home/Hero"
import Risparmiare from "../components/home/Risparmiare"
import Vantaggi from "../components/home/Vantaggi"
import ComeFunziona from "../components/home/ComeFunziona"
import News from "../components/home/News"
import Operatori from "../components/home/Operatori"
import Seo from "../components/seo"
import CardSlide from "../components/home/CardSlide"
const IndexPage = () => {
  return (
    <Layout>
      <Seo title="Un mondo di servizi, senza muoverti da casa" titleType={1} />
      <Hero />
      <ComeFunziona />
      <Risparmiare />
      <Vantaggi />
      <CardSlide />
      <News />
      <Operatori />
    </Layout>
  )
}

export default IndexPage
